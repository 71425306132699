'use strict';

module.exports = function () {
	$(document).ready(function () {
		var $usercentricsContainer = $('#usercentrics-container');
		if (!$usercentricsContainer.length) {
			return;
		}

		var url = $usercentricsContainer.data('url');
		if (!url) {
			return;
		}

		var usercentricsObj = window.localStorage.getItem('usercentrics');
		if (!usercentricsObj) {
			return;
		}

		var dataParameters = {};

		try {
			var usercentricsParsed = JSON.parse(usercentricsObj);
			if (usercentricsParsed) {
				var marketCategory = usercentricsParsed.categories.find(function (category) {
					return category.categorySlug === 'marketing';
				});

				if (marketCategory) {
					var trackingConsestSFCC = marketCategory.consents.find(function (consent) {
						return consent.dataProcessors[0] === 'Salesforce SFCC Tracking';
					});
					dataParameters.trackingSFCC = trackingConsestSFCC ? trackingConsestSFCC.consentStatus : false;

					var retailAdsConsest = marketCategory.consents.find(function (consent) {
						return consent.dataProcessors[0] === 'retailAds';
					});
					dataParameters.retailAds = retailAdsConsest ? retailAdsConsest.consentStatus : false;
				}
			}
		} catch (err) {
			usercentricsParsed = {};
		}

		if (!Object.keys(dataParameters).length) {
			return;
		}

		$.ajax({
			url: url,
			method: 'POST',
			data: dataParameters
		});
	});
};
