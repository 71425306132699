'use strict';

module.exports = function () {
    $(document).ready(function () {
        const $body = $('body');
        const $header = $('#pageHeader');
        const scrollOffset = $header.data('scroll-offset');

        if (scrollOffset !== null) {
            const updateHeaderHeight = () => {
                const headerHeight = $header.outerHeight();
                $header.css('--header-height', headerHeight + 'px');
                return headerHeight;
            };

            let headerHeight = updateHeaderHeight();
            let lastScrollTop = 0;

            $header.css('--header-height', headerHeight + 'px');
            $body.css('padding-top', headerHeight);
            $header.addClass('sticky');

            $(window).on('scroll', function () {
                const currentScrollTop = $(this).scrollTop();
                const scrollDifference = currentScrollTop - lastScrollTop;

                if (currentScrollTop <= headerHeight) {
                    $header.removeClass('scroll-start');
                } else {
                    $header.addClass('scroll-start');
                }

                if (Math.abs(scrollDifference) >= scrollOffset) {
                    if (scrollDifference < 0) {
                            $header.addClass('scroll-up');
                    } else {
                        $header.removeClass('scroll-up');
                        $header.find('.minicart .popover').removeClass('show');
                    }

                    lastScrollTop = currentScrollTop;
                }
            });

            $body.on('promoBannerClosed', function () {
                headerHeight = updateHeaderHeight();
                $body.css('padding-top', headerHeight);
            });

            $(window).on('resize', function () {
                headerHeight = updateHeaderHeight();
                $body.css('padding-top', headerHeight);
            });
        }
    });
};
