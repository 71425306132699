'use strict';

/**
 *
 */
function retailAdsCallback() {
	var dataLayer = window.dataLayer;
	if (!dataLayer || !dataLayer.length) {
		setTimeout(retailAdsCallback, 1000);
		return;
	}

	var orderConfirmationEvent = dataLayer.find(function (eventObj) {
		return eventObj.event === 'order_confirmation';
	});
	if (!orderConfirmationEvent) {
		setTimeout(retailAdsCallback, 1000);
		return;
	}

	window.tsCategory = '1'; // Kennung für die Art der Bestellung
	window.tsProgramID = '2276'; // bitte nicht ändern
	window.tsValue = orderConfirmationEvent.ecommerce.netto; // Netto Bestellwert inkl. aller Rabatte. Bei Leadvergütung auf "0" setzen
	window.tsVoucherCode = orderConfirmationEvent.ecommerce.voucher; // Gutscheinname
	window.tsOrderID = orderConfirmationEvent.ecommerce.order_number; // Bestellnummer (benötigt)

	var imgSrc = [
		'https://rast.schaffrath.com/tl.php?tt=bck&p=2276&oid=',
		window.tsOrderID,
		'&ocategory=1&ovalue=',
		window.tsValue,
		'&ovc=',
		window.tsVoucherCode
	].join('');

	var imgEl = document.createElement('img');
	imgEl.setAttribute('src', imgSrc);
	imgEl.setAttribute('alt', '');
	imgEl.setAttribute('border', 0);
	imgEl.setAttribute('width', 0);
	imgEl.setAttribute('height', 0);

	document.body.appendChild(imgEl);
}

/**
 *
 */
function retailLandingPage() {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://rast.schaffrath.com/ts.js';

	document.getElementsByTagName('head')[0].appendChild(script);
}

/**
 *
 */
function retailBasketFreeze() {
	var isConfirmationPage = window.location.href.indexOf('bestaetigung') !== -1;
	var isScriptAdd = isConfirmationPage;
	if (!isScriptAdd) {
		var value = '; ' + document.cookie;
		var parts = value.split('; isBasketFreeze=');
		var basketFreezeCookieValue = '';

		if (parts.length === 2) {
			basketFreezeCookieValue = parts.pop().split(';').shift();
		}

		isScriptAdd = !basketFreezeCookieValue;
	}

	if (isScriptAdd) {
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://rast.schaffrath.com/ts.js';

		document.getElementsByTagName('head')[0].appendChild(script);
	}
}

/**
 *
 */
function runRetailAdsScripts() {
	var $usercentricsContainer = $('#usercentrics-container');
	if (!$usercentricsContainer.length) {
		return;
	}

	if ($usercentricsContainer.data('retailAdsLandingPageEnabled')) {
		retailLandingPage();
	}

	if ($usercentricsContainer.data('retailAdsOrderConfirmationCallbackEnabled')) {
		var $orderConfirmationPage = $('.page');
		var isOrderConfirmation = $orderConfirmationPage.length > 0 && $orderConfirmationPage.data('action') === 'Order-Confirm';
		if (isOrderConfirmation) {
			retailAdsCallback();
		}
	}
}

/**
 * @description updates consent status based on user choice on usercentrics V2
 */
function updateSFCCConsentStatus() {
	const sfccTracking = window.UC_UI.getServicesBaseInfo().find(function (data) {
		return data.name === 'Salesforce SFCC Tracking';
	});

	if (!sfccTracking || !sfccTracking.consent) {
		return;
	}

	var $usercentricsContainer = $('#usercentrics-container');
	let url = $usercentricsContainer.data('url');

	$.ajax({
		url: url,
		method: 'POST',
		data: {
			trackingSFCC: sfccTracking.consent.status
		}
	});
}

/**
 *
 */
function initRetailAdsScripts2() {
	var retailAds = window.UC_UI.getServicesBaseInfo().find(function (data) {
		return data.name === 'retailAds';
	});

	if (!retailAds || !retailAds.consent.status) {
		return;
	}

	runRetailAdsScripts();
}

/**
 *
 */
function initRetailAdsScripts1() {
	var retailAdsStatus = false;
	var usercentricsObj = window.localStorage.getItem('usercentrics');

	try {
		var usercentricsParsed = JSON.parse(usercentricsObj);
		if (usercentricsParsed) {
			var marketCategory = usercentricsParsed.categories.find(function (category) {
				return category.categorySlug === 'marketing';
			});

			var retailAds = marketCategory.consents.find(function (consent) {
				return consent.dataProcessors[0] === 'retailAds';
			});

			retailAdsStatus = retailAds.consentStatus;
		}
	} catch (err) {
		console.error('can not parse usercentrics from local storage');
	}

	if (!retailAdsStatus) {
		return;
	}

	runRetailAdsScripts();
}

/**
 *
 * @returns
 */
function initBasketFreeze() {
	var $usercentricsContainer = $('#usercentrics-container');
	if (!$usercentricsContainer.length) {
		return;
	}

	var basketFreeze = window.UC_UI.getServicesBaseInfo().find(function (data) {
		return data.name === 'Basket Freeze';
	});

	if (!basketFreeze || !basketFreeze.consent.status) {
		return;
	}

	if ($usercentricsContainer.data('retailAdsBasketFreezeEnabled')) {
		retailBasketFreeze();
	}
}

module.exports = function init() {
	if (!window.UC_UI) {
		setTimeout(init, 1000);
		return;
	}

	// updating consent status after window.UC_UI loaded
	updateSFCCConsentStatus();

	var $usercentricsContainer = $('#usercentrics-container');
	if (!$usercentricsContainer.length) {
		return;
	}

	var retailAdsUsercentricsCmpVersion = $usercentricsContainer.data('retailAdsUsercentricsCmpVersion');
	if (retailAdsUsercentricsCmpVersion === 2) {
		initRetailAdsScripts2();
	} else if (retailAdsUsercentricsCmpVersion === 1) {
		initRetailAdsScripts1();
	}

	initBasketFreeze();
};
