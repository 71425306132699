'use strict';

module.exports = {
	orderByEmail: function () {
		$('body').on('click', '.order-by-email .btn', function (event) {
			event.preventDefault();
			var locationURL = 'mailto:' + $(this).data('mail') + '?subject=' + $(this).data('subject');
			if ($(this).data('bodyUrl')) {
				locationURL = locationURL + '&body=' + $(this).data('bodyUrl');
			}
			window.location = locationURL;
		});
	}
};
