'use strict';

/**
 * Call this function when a user clicks on a product link. This function uses the event
 * callback dataLayer variable to handle navigation after the ecommerce data has been sent
 * to Google Analytics.
 * @param {Array<Object>} dataForDataLayer An object representing a product.
 */
function pushData(dataForDataLayer) {
	if (Array.isArray(dataForDataLayer)) {
		for (let data of dataForDataLayer) {
			dataLayer.push(data); // eslint-disable-line no-undef
		}
	} else {
		dataLayer.push(dataForDataLayer); // eslint-disable-line no-undef
	}
}

module.exports = function () {
	$('body')
		.on('product:afterAddToCart', function (_, response) {
			if (response.action === 'Cart-AddProduct') {
				pushData(response.responseData);
			}
		});

	$('body')
		.on('cart:update', function (_, response) {
			if (response.action === 'Cart-RemoveProductLineItem'
			|| response.action === 'Cart-UpdateQuantity') {
				pushData(response.responseData);
			}
		})
		.on('checkout:trackData', function (e, response) {
			if (response.action === 'CheckoutShippingServices-SubmitShipping'
			|| response.action === 'CheckoutServices-SubmitPayment'
			|| response.action === 'CheckoutServices-PlaceOrder') {
				pushData(response.responseData);
			}
		});
};
