'use strict';

const utils = require('../utils/index');

/* eslint-disable no-use-before-define */

var FIELDS_SELECTOR = 'form.js-validate input:not(:disabled), form select:not(:disabled), form textarea:not(:disabled), form .js-checkbox-validation:not(:disabled)';
var LIST_CHECKBOXES_SELECTOR = 'form .js-list-checkboxes:not(:disabled)';

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
	var valid = true;
	var $inputFields = $(this).find(FIELDS_SELECTOR);

	$inputFields.each(function () {
		processCustomValidation(this);
	});

	if (this.checkValidity && !this.checkValidity()) {
		// safari
		valid = false;

		if (event) {
			event.preventDefault();
			event.stopPropagation();
			event.stopImmediatePropagation();
		}

		$inputFields.each(function () {
			if (!this.validity.valid) {
				$(this).trigger('invalid', this.validity);
			}
		});
	}

	var $recaptchaField = $('.js-recaptcha-field');

	if ($recaptchaField.length && event.originalEvent && event.originalEvent.isTrusted) {
		window.grecaptcha.ready(function () {
			window.grecaptcha.execute($recaptchaField.data('site-key'), {
				action: $recaptchaField.data('recaptcha-action')
			}).then(function (token) {
				$recaptchaField.val(token);
				$(event.currentTarget).submit();
			});
		});

		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation();
	}

	return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
	var $form = $(form);

	$form.find('.is-invalid').removeClass('is-invalid');
	$form.find('.invalid-feedback')
		.text('')
		.attr('hidden', true);
}

/**
 * Validate form input element using custom checks
 * @param {HTMLInputElement} field - Form input element
 * @returns {void}
 */
function processCustomValidation(field) {
	var $field = $(field);
	var validationMethodsString = $field.data('validation-methods');
	var validationMethods = validationMethodsString ? validationMethodsString.split(':') : [];
	var result = {
		valid: true,
		message: ''
	};

	if (validationMethods in clientSideValidation.validationMethods) {
		result = clientSideValidation.validationMethods[validationMethods](field);
	}

	if (!result.valid) {
		if (!$field.hasClass('js-list-checkboxes')) {
			field.setCustomValidity(result.message);
		} else {
			$field.data('errorMsg', result.message);
		}
	}

	return result.valid;
}

var clientSideValidation = {
	invalid: function () {
		$('body').on('invalid', FIELDS_SELECTOR, function (e) {
			var $field = $(this);

			e.preventDefault();

			if ($field.hasClass('js-list-checkboxes-validity-status')) {
				$field.parents('fieldset').trigger('invalid');
				return;
			}

			if (!this.validity.valid) {
				var validationMessage = this.validationMessage;

				$field.addClass('is-invalid');

				if (this.validity.patternMismatch && $field.data('pattern-mismatch')) {
					validationMessage = $field.data('pattern-mismatch');
				}

				var isRangeEnabled = $field.data('validation-rule-range-enabled') === undefined || $field.data('validation-rule-range-enabled') === true;
				if (isRangeEnabled) {
					if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) && $field.data('range-error')) {
						validationMessage = $field.data('range-error');
					}
					if ((this.validity.tooLong || this.validity.tooShort) && $field.data('range-error')) {
						validationMessage = $field.data('range-error');
					}
				}

				if (this.validity.valueMissing && $field.data('missing-error')) {
					validationMessage = $field.data('missing-error');
				}

				$field.parents('.form-group').find('.invalid-feedback')
					.text(validationMessage)
					.attr('hidden', false);
			}

			var $form = $field.closest('form');
			if (!($form.length && $form.data('noScroll'))) {
				$('html,body')
				.animate({
						scrollTop:
							$('.form-control.is-invalid, .form-check-input.is-invalid')
								.first()
								.offset()
								.top - 50
					}, 'slow');
			}
		});

		$('body').on('invalid', LIST_CHECKBOXES_SELECTOR, function (e) {
			e.preventDefault();

			var $list = $(this);
			var validationMessage = $list.data('errorMsg');
			if (!validationMessage) {
				processCustomValidation(this);
				validationMessage = $list.data('errorMsg');
			}

			if (validationMessage) {
				$list.addClass('is-invalid');

				var $inputValidation = $list.find('js-list-checkboxes-validity-status');
				$inputValidation.addClass('is-invalid');

				$list.find('.invalid-feedback')
					.text(validationMessage)
					.attr('hidden', false);

					$('html,body')
						.animate({
							scrollTop:	$list.offset().top - 50
						}, 'slow');
			}
		});
	},

	fieldBlur: function () {
		$('body').on('blur', FIELDS_SELECTOR, function () {
			var $field = $(this);

			if ($field.attr('type') === 'checkbox') {
				var $listCheckboxes = $field.parents('.js-list-checkboxes');
				if ($listCheckboxes.length) {
					$listCheckboxes.trigger('list_checkboxes_blur');
					return;
				}
			}

			this.setCustomValidity('');

			if (!this.validity.valid || !processCustomValidation(this)) {
				$field.trigger('invalid');
			} else {
				$field.removeClass('is-invalid');
				$field.parents('.form-group').find('.invalid-feedback')
					.text('')
					.attr('hidden', true);
			}
		});

		$('body').on('list_checkboxes_blur', LIST_CHECKBOXES_SELECTOR, function () {
			var $list = $(this);

			if (!processCustomValidation(this)) {
				$list.trigger('invalid');
			} else {
				$list.removeClass('is-invalid');
				$list.data('errorMsg', '');
				$list.find('.invalid-feedback')
					.text('')
					.attr('hidden', true);
			}
		});
	},

	initListCheckboxes: function () {
		// init list of checkboxes where only one should be selected
		$('body').on('change', LIST_CHECKBOXES_SELECTOR + ' input[type=checkbox]', function () {
			var $lastCheckedCheckbox = $(this);
			var $list = $lastCheckedCheckbox.parents(LIST_CHECKBOXES_SELECTOR);
			var lastCheckedCheckboxID = $lastCheckedCheckbox.attr('id');
			var $validityInput = $list.find('.js-list-checkboxes-validity-status');

			// uncheck other selected checkboxes except last checked checkbox
			var $checkedCheckboxes = $list.find('input[type=checkbox]:checked');
			if ($checkedCheckboxes.length) {
				$checkedCheckboxes.each(function () {
					var $this = $(this);
					if (lastCheckedCheckboxID !== $this.attr('id')) {
						$this.prop('checked', false);
					} else {
						$validityInput.prop('checked', true);
					}
				});
			} else {
				$validityInput.prop('checked', false);
			}

			// trigger blur event for checkbox for IOS devices
			if (utils.isMobileOS()) {
				$lastCheckedCheckbox.trigger('blur');
			}
		});
	},

	submit: function () {
		$('form.js-validate').on('submit', function (e) {
			return validateForm.call(this, e);
		});
	},

	submitButtonClick: function () {
		$('form.js-validate button[type="submit"], form.js-validate input[type="submit"]').on('click', function () {
			var $form = $(this).parents('form');
			clearForm($form);

			var invalidFormElements = $form[0].querySelectorAll(':invalid:not(form):not(fieldset)');
			$(invalidFormElements).each(function () {
				$(this).trigger('invalid');
			});
		});
	},

	validationMethods: {
		kitchenConsultationZip: function (field) {
			var $field = $(field);
			var value = $field.val();
			var allowedCodes = $field.data('allowed-codes');
			var result = {
				valid: false,
				message: ''
			};

			if ($field.hasClass('js-dis-allow-postalcode-validation')) {
				result.valid = true;

				return result;
			}

			if (!Array.isArray(allowedCodes) || !field.validity.valid) {
				return result;
			}

			if (allowedCodes.indexOf(value) > -1) {
				result.valid = true;
			} else {
				result.message = $field.data('zip-error-msg');
			}

			return result;
		},
		kitchenConsultationLocation: function (listCheckboxes) {
			var result = {
				valid: false,
				message: ''
			};

			var $listCheckboxes = $(listCheckboxes);
			var $checkedInput = $listCheckboxes.find('.js-checkbox-validation:checked');
			if ($checkedInput.length) {
				result.valid = true;
			} else {
				result.message = $listCheckboxes.data('errorMsgRequiredSelectedCheckbox');
			}

			// postal code validation should not be applied to video and store options
			var $postalCodeField = $('#appointment-postal-code-field');
			if ($checkedInput.is('#appointment-location-store-checkbox,#appointment-location-video-checkbox')) {
				$postalCodeField.addClass('js-dis-allow-postalcode-validation');
			} else {
				$postalCodeField.removeClass('js-dis-allow-postalcode-validation');
			}

			if ($postalCodeField.val()) {
				$postalCodeField.trigger('blur');
			}

			return result;
		},
		password: function (field) {
			var $field = $(field);
			var value = $field.val();
			var result = {
				valid: true,
				message: ''
			};

			if ($field.data('validation-rule-missed-number-enabled') === true) {
				var numberRegEx = /[1-9][0-9]*/;
				if (!numberRegEx.test(value)) {
					result.valid = false;
					result.message = $field.data('missed-number-error');

					return result;
				}
			}

			if ($field.data('validation-rule-missed-lower-upper-case-letter-enabled') === true) {
				var lowercaseRegEx = /[a-z]/;
				var uppercaseRegEx = /[A-Z]/;
				if (!lowercaseRegEx.test(value) || !uppercaseRegEx.test(value)) {
					result.valid = false;
					result.message = $field.data('missed-lower-upper-case-letter-error');

					return result;
				}
			}

			if ($field.data('validation-rule-missed-special-character-enabled') === true) {
				var specialCharacterRegEx = /[^A-Za-z0-9]/;
				if (!specialCharacterRegEx.test(value)) {
					result.valid = false;
					result.message = $field.data('missed-special-character-error');

					return result;
				}
			}

			return result;
		}
	},

	functions: {
		validateForm: function (form, event) {
			validateForm.call($(form), event || null);
		},
		clearForm: clearForm
	}
};

module.exports = clientSideValidation;
