'use strict';

require('base/carousel');

$(function () {
	const $allCarousels = $('.carousel');

	$allCarousels.each(function () {
		const $this = $(this);
		if (!$this.data('interval')) {
			return;
		}

		const timeout = parseInt($this.data('timeout'), 10);
		$this.carousel({
			cycle: true,
			interval: timeout,
            ride: 'carousel'
		});
	});
});
